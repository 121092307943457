import styled from 'styled-components';

export const ImageComparisonContainer = styled.div`
	display: grid;
	grid-template-columns: 2fr 1fr 1fr;
	width: 100%;
	align-items: center;
	justify-items: center;
	margin: 30px 0;
	& .imageContainer {
		height: auto;
		max-width: 150px;
		img {
			height: auto;
			width: 100%;
		}
		margin: 10px;
	}
`;
