import styled from 'styled-components';
import { media } from '../../../../utils/helpers';

export const Div = styled.div`
	margin-bottom: 20px;
	margin-top: 20px;
	width: 100%;
	${media.tablet`
        margin-top: 190px;
    `}
`;

export const StyledDiv = styled.div`
	margin-top: 60px;
`;
