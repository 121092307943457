import * as S from './style';
import React from 'react';
const CTAWrapper = ({ children }) => {
	return <S.Div>{children}</S.Div>;
};
export default CTAWrapper;

export const HomepageCTAWrapper = ({ children }) => {
	return <S.StyledDiv>{children}</S.StyledDiv>;
};
