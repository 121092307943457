import styled from 'styled-components';
import {
	setFontFamily,
	setFontWeight,
	setFontSize,
	setColors,
	mediaQuery,
} from '../../../../utils/helpers';

export const PlanComparisonWrapper = styled.div`
	margin-top: 100px;
	${mediaQuery.mobileXL`
		display: none;
	`}
`;

export const PlanTableWrapper = styled.div`
	margin-bottom: 20px;
`;

export const RootRow = styled.div`
	display: grid;
	grid-template-columns: ${props => `2fr repeat(${props.maxValueCells}, 1fr)`};
	align-items: center;
`;

export const HeaderRow = styled(RootRow)``;

export const CategoryRow = styled(RootRow)`
	padding-bottom: ${props => (props.showCategory ? '10px' : 0)};
	padding-top: ${props => (props.showCategory ? '10px' : 0)};
`;

export const Row = styled(RootRow)`
	border-top: 1px solid ${setColors.formBorderGray};
	padding-bottom: 10px;
	padding-top: 10px;
`;

export const PlanName = styled.h2`
	font-family: ${setFontFamily.book};
	font-size: ${setFontSize.mediumTiny};
	font-weight: ${setFontWeight.bold};
	font-stretch: normal;
	font-style: normal;
	line-height: 1.05;
	letter-spacing: normal;
	text-align: center;
	color: ${setColors.lightBlack};
	margin: 0 0 10px 0;
`;

export const Category = styled.h3`
	font-family: ${setFontFamily.medium};
	font-size: ${setFontSize.mediumTiny};
	font-weight: ${setFontWeight.normal};
	font-stretch: normal;
	font-style: normal;
	line-height: 1.05;
	letter-spacing: normal;
	color: ${setColors.lightBlack};
	margin: 0;
`;

export const Pricing = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	span {
		font-family: ${setFontFamily.bold};
		font-weight: ${setFontWeight.bold};
		font-stretch: normal;
		font-style: normal;
		line-height: 1;
		letter-spacing: normal;
		color: ${setColors.lightBlack};
	}
	span.customValue {
		font-size: ${setFontSize.smaller};
	}
	span.priceDollar {
		vertical-align: top;
		margin-right: 3px;
	}
	span.priceValue {
		font-size: ${setFontSize.mediumLarge};
		align-self: center;
		line-height: 0.7;
	}
	span.priceDollar,
	span.priceTime {
		font-size: ${setFontSize.mediumTiny};
	}
`;

export const Cell = styled.div`
	font-family: ${setFontFamily.book};
	font-size: ${setFontSize.veryVeryTiny};
	font-weight: ${setFontWeight.normal};
	font-stretch: normal;
	font-style: normal;
	line-height: 1.29;
	letter-spacing: normal;
	text-align: center;
	color: ${setColors.lightBlack};
	position: relative;
	svg {
		font-size: ${setFontSize.smaller};
		position: absolute;
		transform: translate(-50%, -50%);
		color: ${setColors.themeBlue};
	}
	svg.notAvailable {
		color: ${setColors.disabledGray};
		font-size: ${setFontSize.tiny};
	}
	& svg.cross {
		color: ${setColors.disabledGray};
	}
`;

export const FeatureCell = styled(Cell)`
	text-align: left;
	padding-left: 20px;
	img {
		vertical-align: middle;
		margin-left: 5px;
		opacity: 0.9;

		&:hover {
			opacity: 1;
		}
	}
`;
