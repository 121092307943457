import React from 'react';
import { setColors } from '../../../../../utils/helpers';
import { ButtonTo } from '../CTA';
import { BlueWrapper } from './MinimalBlueCTA.styled';

const MinimalBlueCTA = ({ title, buttonText, ButtonURL }) => {
	return (
		<BlueWrapper>
			<h3>{title}</h3>
			<div>
                <ButtonTo url={ButtonURL} btnColor={setColors.black}>
                    {buttonText}
                </ButtonTo>
            </div>
		</BlueWrapper>
	);
};

export default MinimalBlueCTA;
