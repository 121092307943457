import styled from 'styled-components';
import { mediaQuery, setColors, setFontSize } from '../../../../utils/helpers';

export const InfoCardWrapper = styled.div`
	background-color: ${setColors.warmGray};
	border-radius: 10px;
	padding: 20px 30px;
	min-height: 295px;
	width: 100%;
	max-width: 341px;
	box-sizing: border-box;
	justify-self: center;
	img {
		height: 35px;
		width: 35px;
	}
	h3 {
		font-size: ${setFontSize.mediumSmall};
		margin: 15px 0 22px 0;
	}
	p {
		font-size: ${setFontSize.mediumTiny};
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0;
		margin: 0;
	}
	a {
		color: ${setColors.themeBlue};
		text-decoration: none;
		display: inline-flex;
		align-items: center;
		margin: 26px 0 6px 0;
		svg {
			margin-left: 5px;
		}
	}
`;

export const InfoCardGrid = styled.div`
	display: grid;
	grid-gap: 35px;
	grid-template-columns: repeat(3, 1fr);
	width: 100%;

	${mediaQuery.mobileXL`
        grid-template-columns: 1fr;
    `}
`;
