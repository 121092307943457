import React from 'react';
import CTA from '../CTA';
import MinimalBlueCTA from '../MinimalBlueCTA/MinimalBlueCTA.component';

const MasterCTA = ({ cta }) => {
	const {
		type,
		title,
		subtitle,
		primaryButtonText,
		primaryButtonUrl,
		secondaryButtonText,
		secondaryButtonUrl,
	} = cta;
	switch (type) {
		case 'black_cta':
			return (
				<CTA
					title={title}
					subtitle={subtitle}
					primaryButtonText={primaryButtonText}
					primaryButtonUrl={primaryButtonUrl}
					secondaryButtonText={secondaryButtonText}
					secondaryButtonUrl={secondaryButtonUrl}
				/>
			);
		case 'blue_cta':
			return (
				<MinimalBlueCTA
					title={title}
					buttonText={primaryButtonText}
					ButtonURL={primaryButtonUrl}
				/>
			);
		default:
		//pass
	}
};

export default MasterCTA;
