import { Link } from 'gatsby';
import React from 'react';
import { checkLink } from '../../../../utils/helpers';
import { InfoCardWrapper } from './InfoCard.styled';
import { IconNext } from '../icons/index';

const LinkTo = ({ url, children }) => {
	const urlType = checkLink(url);
	if (urlType === 0) {
		return (
			<a href={url} target="_blank" rel="noopener noreferrer">
				{children}
			</a>
		);
	} else if (urlType === 1) {
		return (
			<a href={url} target="_self">
				{children}
			</a>
		);
	} else {
		return <Link to={`${url}/`}>{children}</Link>;
	}
};

const InfoCard = ({ image, title, subtile, linkText, linkUrl }) => {
	return (
		<InfoCardWrapper>
			<img src={image.file.url} alt={title} />
			<h3>{title}</h3>
			<p>{subtile}</p>
			{linkUrl && linkText && (
				<LinkTo url={linkUrl}>
					<span>{linkText}</span>
					<IconNext height={16} width={20} />
				</LinkTo>
			)}
		</InfoCardWrapper>
	);
};

export default InfoCard;
