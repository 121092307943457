import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

import InfoBox from '../components/global/info-box/InfoBox';
import Layout from '../components/global/layout/Layout';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import SEO from '../components/global/seo';
import Customers from '../components/global/customers/Customers';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import InfoCard from '../components/global/InfoCard/InfoCard.component';
import CTAWrapper from '../components/home-page/CtaWrapper/CTAWrapper';
import MasterCTA from '../components/global/CTA/MasterCTA/MasterCTA.component';
import AccordionWrapper from '../components/alternativePage/AccordionWrapper/AccordionWrapper';
import Faq from '../components/global/Faq/Faq';

import { mediaQuery, setColors } from '../../utils/helpers';

import { Container } from '../components/global/customers/styles';
import { Section } from '../components/home-page/about-chatbots/styles';
import * as S from '../components/demand-generation/styles';
import { InfoCardGrid } from '../components/global/InfoCard/InfoCard.styled';
import { SliderContainer } from '../components/customer-support/styles';
import { ImageComparisonContainer } from '../components/alternativePage/styles';

const FAQWrapper = styled.div`
	background: ${setColors.warmGray};
	padding: 140px 0 0 0;
	margin: 100px 0 0 0;
	${mediaQuery.mobileXL`
		padding: 35px 0 0 0;
		margin: 55px 0 0 0;
	`}
`;

const ComparisonPageTemplatev2 = ({ data }) => {
	const {
		hero,
		customerTitle,
		customerImages,
		section1Title,
		section1Richtext,
		section1Image,
		infoSection,
		comparisonTitle,
		comparisonImageOne,
		comparisonImageTwo,
		comparisonTable,
		otherCompetitorsTitle,
		otherCompetitorsCards,
		faqSubtitle,
		faqTitle,
		faqQuestions,
		cta,
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
		seoNoIndex,
		seoJsonSchema,
	} = data.contentfulPageAlternativePageV2;
	return (
		<Layout>
			<SEO
				title={seoTitle}
				description={seoDescription}
				image={seoImage}
				article={seoArticle}
				noIndex={seoNoIndex}
				seoJsonSchema={seoJsonSchema}
			/>
			<WrapperOne>
				<InfoBox
					image={hero.image}
					title={hero.title}
					subtitle={hero.subtitle.subtitle}
					hideImage="true"
					buttonText={hero.button.linkText}
					buttonLink={hero.button.linkUrl}
					isHeroHeader
				/>
			</WrapperOne>
			<Customers
				section2Images={customerImages}
				section2Title={customerTitle}
			/>
			<Container>
				<Section>
					<SectionTextBox
						title={section1Title.section1Title}
						richTextContent={section1Richtext}
						largeHeading="true"
					/>
					<Img
						fluid={section1Image.fluid}
						alt={section1Image.title}
						style={{ maxWidth: '927px', margin: '0 auto', width: '100%' }}
					/>
				</Section>
				{infoSection.map(section => (
					<S.SectionWrapper>
						<InfoBox
							image={section.image}
							title={section.title}
							subtitle={section.subtitle.subtitle}
							invert={!section.keepImageOnRight}
							subHeading="true"
							videoBorderRadius={'10px'}
							header={section.header}
						/>
					</S.SectionWrapper>
				))}
				<SliderContainer>
					<SectionTextBox title={comparisonTitle} largeHeading="true" />
				</SliderContainer>
				<ImageComparisonContainer>
					<div></div>
					<div className="imageContainer">
						<img
							src={comparisonImageOne.file.url}
							alt={comparisonImageOne.title}
						/>
					</div>
					<div className="imageContainer">
						<img
							src={comparisonImageTwo.file.url}
							alt={comparisonImageTwo.title}
						/>
					</div>
				</ImageComparisonContainer>
				<AccordionWrapper comparisonTable={comparisonTable} />
				<SliderContainer>
					<SectionTextBox title={otherCompetitorsTitle} largeHeading="true" />
				</SliderContainer>
				<InfoCardGrid style={{ marginTop: '20px' }}>
					{otherCompetitorsCards.map(card => (
						<InfoCard
							image={card.icon}
							title={card.title}
							subtile={card.description.description}
							linkText={card.linkText}
							linkUrl={card.linkUrl}
						/>
					))}
				</InfoCardGrid>
			</Container>
			<FAQWrapper>
				<Container>
					<Faq title={faqTitle} subtitle={faqSubtitle} content={faqQuestions} />
				</Container>
			</FAQWrapper>
			<Container>
				<CTAWrapper>
					<MasterCTA cta={cta} />
				</CTAWrapper>
			</Container>
		</Layout>
	);
};

export default ComparisonPageTemplatev2;
export const query = graphql`
	query($slug: String) {
		contentfulPageAlternativePageV2(slug: { eq: $slug }) {
			hero {
				...infoSectionFragment
			}
			customerTitle
			customerImages {
				...asset
			}
			section1Title {
				section1Title
			}
			section1Richtext {
				raw
			}
			section1Image {
				...asset
			}
			infoSection {
				...infoSectionFragment
			}
			comparisonTitle
			comparisonImageOne {
				...asset
			}
			comparisonImageTwo {
				...asset
			}
			comparisonTable {
				tableBody {
					category
					rows {
						helperText
						feature
						cells {
							text
							isText
							isChecked
						}
					}
				}
			}
			otherCompetitorsTitle
			otherCompetitorsCards {
				...doMore
			}
			faqSubtitle {
				raw
			}
			faqTitle
			faqQuestions {
				question
				answer {
					type
					content
				}
			}
			cta {
				...cta
			}
			seoTitle
			seoDescription
			seoArticle
			seoImage {
				...asset
			}
			seoNoIndex
			seoJsonSchema {
				internal {
					content
				}
			}
		}
	}
`;
