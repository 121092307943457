import styled from 'styled-components';
import { mediaQuery } from '../../../utils/helpers';

export const SectionWrapper = styled.div`
	img {
		max-width: 540px;
	}
`;

export const SliderWrapper = styled.div`
	.gatsby-image-wrapper {
		max-width: 747px;
		max-height: 500px;
	}
`;

export const SliderContainer = styled.div`
	margin-top: 110px;
`;

export const SnippetWrapper = styled.div`
	padding-top: 130px;
	${mediaQuery.mobileXL`padding-top: 5px`}
`;
