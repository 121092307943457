import React from 'react';
import ReactTooltip from 'react-tooltip';
import { PlanTable } from '../../pricing-v2/PlanComparison/PlanComparison';
import {
	AccordionItemHeading,
	Accordion,
	AccordionItem,
	AccordionItemButton,
	AccordionItemPanel,
} from '../Accordion/Accordion';

const AccordionWrapper = ({ comparisonTable }) => {
	const preExpanded = comparisonTable.tableBody.map((_, index) => index);
	return (
		<>
			<Accordion
				allowMultipleExpanded
				allowZeroExpanded
				preExpanded={preExpanded}
			>
				{comparisonTable.tableBody.map((tableItem, index) => (
					<AccordionItem key={index} uuid={index}>
						<AccordionItemHeading>
							<AccordionItemButton>{tableItem.category}</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel>
							<PlanTable
								showMainHeader={false}
								showCategory={false}
								tableData={tableItem}
								key={`table-${index}`}
								maxValueCells={2}
								useCross
							/>
						</AccordionItemPanel>
					</AccordionItem>
				))}
			</Accordion>
			<ReactTooltip effect="solid" type="dark" />
		</>
	);
};

export default AccordionWrapper;
