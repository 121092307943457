import React from 'react';
import { BiCheck, BiMinus, BiX } from 'react-icons/bi';
import * as S from './styles';
import QuestionMark from '../../../images/Question.png';
import ReactTooltip from 'react-tooltip';
export const PlanTable = ({
	showMainHeader,
	planDetails,
	tableData,
	showAnnualPlans,
	maxValueCells = 4,
	showCategory = true,
	useCross,
}) => {
	return (
		<S.PlanTableWrapper>
			{showMainHeader ? (
				<S.HeaderRow maxValueCells={maxValueCells}>
					<S.PlanName></S.PlanName>
					{planDetails.map((plan, i) => (
						<S.PlanName key={`${tableData.category}-plan-${i}`}>
							{plan.name}
						</S.PlanName>
					))}
				</S.HeaderRow>
			) : null}
			<S.CategoryRow showCategory={showCategory} maxValueCells={maxValueCells}>
				{showCategory && <S.Category>{tableData.category}</S.Category>}
				{showMainHeader ? (
					<>
						{planDetails.map((plan, i) => (
							<S.Pricing key={`${tableData.category}-pricing-plan-${i}`}>
								{plan.isCustom ? (
									<span className="customValue">
										{showAnnualPlans ? plan.priceAnnual : plan.priceMonth}
									</span>
								) : (
									<div className="priceWrapper">
										<span className="priceDollar">$</span>
										<span className="priceValue">
											{showAnnualPlans ? plan.priceAnnual : plan.priceMonth}
										</span>
										<span className="priceTime">
											{showAnnualPlans ? '/yr' : '/mo'}
										</span>
									</div>
								)}
							</S.Pricing>
						))}
					</>
				) : null}
			</S.CategoryRow>
			{tableData.rows.map((tableRow, i) => (
				<S.Row
					key={`${tableData.category}-table-row-${i}`}
					maxValueCells={maxValueCells}
				>
					<S.FeatureCell>
						{tableRow.feature}
						<img
							src={QuestionMark}
							alt="helper icon"
							data-tip={tableRow.helperText}
						/>
					</S.FeatureCell>
					{tableRow.cells.map((cell, j) => (
						<S.Cell
							key={`${tableData.category}-${tableRow.feature}-cell-${i}${j}`}
						>
							{cell.isText ? (
								cell.text
							) : cell.isChecked ? (
								<BiCheck />
							) : useCross ? (
								<BiX className="cross" />
							) : (
								<BiMinus className="notAvailable" />
							)}
						</S.Cell>
					))}
				</S.Row>
			))}
		</S.PlanTableWrapper>
	);
};

const PlanCompare = ({ planComparison, showAnnualPlans }) => {
	return (
		<>
			<S.PlanComparisonWrapper>
				{planComparison.tableBody.map((data, i) => {
					if (i === 0) {
						return (
							<PlanTable
								showMainHeader={true}
								planDetails={planComparison.plans}
								tableData={data}
								key={`table-${i}`}
								showAnnualPlans={showAnnualPlans}
							/>
						);
					} else {
						return (
							<PlanTable
								showMainHeader={false}
								tableData={data}
								key={`table-${i}`}
							/>
						);
					}
				})}
			</S.PlanComparisonWrapper>
			<ReactTooltip effect="solid" type="dark" />
		</>
	);
};

export const PlanComparison = React.memo(PlanCompare);
