import styled from "styled-components";
import { mediaQuery, setColors, setFontSize } from "../../../../../utils/helpers";


export const BlueWrapper  = styled.div`
    padding: 44px 100px;
    background-color: ${setColors.themeBlue};
    color: ${setColors.white};
    display: grid;
    border-radius: 20px;
    grid-template-columns: 3fr 2fr;
    box-sizing: border-box;
    h3{
        font-size: ${setFontSize.littleLarge}; 
        margin: 20px 0;
    }
    div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    ${mediaQuery.laptopML`
        h3 {
            font-size: ${setFontSize.tinyMedium};
        }
    `}
    ${mediaQuery.mobileXL`
        grid-template-columns: 1fr;
        padding: 44px 60px;
        h3 {
            font-size: ${setFontSize.mediumSmall};
            text-align: center;
        }
        div { 
            justify-content: flex-start;
            a {
                width: 100%;
            }
        }
    `}
`