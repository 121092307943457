import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import {
	Accordion as AccordionComponent,
	AccordionItem as AccordionItemComponent,
	AccordionItemHeading as AccordionItemHeadingComponent,
	AccordionItemButton as AccordionItemButtonComponent,
	AccordionItemPanel as AccordionItemPanelComponent,
} from 'react-accessible-accordion';
import { setColors, setFontFamily } from '../../../../utils/helpers';

const StyledAccordionItemPanel = styled(AccordionItemPanelComponent)`
	transition: height 0.35s ease-in-out;
	overflow: hidden;
	height: ${props => props.height || 'auto'};
`;

export const AccordionItemPanel = props => {
	const [height, setHeight] = useState('auto');
	const [w, setw] = useState(null);
	useEffect(() => {
		window.addEventListener('resize', e => {
			setw(e.target.innerWidth);
		});
		return () => [window.removeEventListener('resize', () => {})];
	}, []);
	const panelRef = useCallback(
		node => {
			if (node !== null) {
				setHeight(`${node.getBoundingClientRect().height}px`);
			}
		},
		// eslint-disable-next-line
		[w],
	);

	return (
		<StyledAccordionItemPanel height={height} {...props}>
			<div ref={panelRef}>{props.children}</div>
		</StyledAccordionItemPanel>
	);
};

export const AccordionItemButton = styled(AccordionItemButtonComponent)`
	background-color: ${setColors.warmGray};
	color: ${setColors.lightBlack};
	font-family: ${setFontFamily.medium};
	cursor: pointer;
	padding: 18px;
	width: 100%;
	font-weight: 400;
	text-align: left;
	box-sizing: border-box;
	&:hover {
		background-color: ${setColors.disabledGray};
	}
	&:before {
		display: inline-block;
		content: '';
		height: 8px;
		width: 8px;
		margin-right: 12px;
		border-bottom: 2px solid currentColor;
		border-right: 2px solid currentColor;
		transform: rotate(-45deg);
		transition: transform 0.35s ease-in-out;
		font-weight: bold;
	}
	&[aria-expanded='true']::before,
	&[aria-selected='true']::before {
		transform: rotate(45deg);
	}
`;

export const Accordion = styled(AccordionComponent)`
	border-radius: 2px;
	width: 100%;
	.accordion__item + .accordion__item {
	}
	${StyledAccordionItemPanel}[hidden] {
		height: 0;
		display: block;
	}
`;

export const AccordionItem = styled(AccordionItemComponent)`
	border-top: 1px solid ${setColors.formBorderGray};
	&:last-child {
		border-bottom: 1px solid ${setColors.formBorderGray};
	}
`;

export const AccordionItemHeading = styled(AccordionItemHeadingComponent)``;
