import styled from 'styled-components';
import { setColors, mediaQuery } from '../../../utils/helpers';

export const HeroWrapper = styled.div`
	img {
		object-fit: cover !important;
		max-height: 555px !important;
		max-width: 555px;
		height: auto !important;
	}
	.gatsby-image-wrapper {
		img {
			object-fit: cover !important;
		}
	}
`;

export const SectionImageWrapper = styled.div`
	background: ${setColors.warmGray};
	img {
		margin: 0 auto;
		width: 70%;
		max-width: 917px;
		display: block;
	}
	margin: 20px 0 0px 0;
`;

export const SectionWrapper = styled.div``;

export const StatsWrapper = styled.div`
	margin: 90px 0 0 0;
`;

export const CTAWrapper = styled.div`
	padding-top: 120px;
	${mediaQuery.mobileXL`padding-top: 54px;padding-bottom: 20px;`};
`;
